import ArticleItem from "~/components/article-item/article-item.js"
import MobileFilters from "~/components/mobile-filters/mobile-filters.js"
import Pagination from "~/components/pagination/pagination.js"
import React from "react"
import StickySidebar from "~/components/sticky-sidebar/sticky-sidebar.js"
import useWindowSize from "~/helpers/hooks/useWindowSize.js"

// Archive component used by Category and Blog pages
const Archive = ({ pageContext, data }) => {
  const {
    allWpPost: { pageInfo },
  } = data
  const { categoryBase, base, name, cat } = pageContext

  const Articles = data.allWpPost.nodes
  const Categories = data.allWpCategory.nodes
  const { currentPage, pageCount } = pageInfo
  const sizes = useWindowSize()

  let title = "Blog";
  if(!!name) title = name;

  // console.log(pageContext);

  return (
    <div className={"container allow-overflow"}>
      <div className={"page-blog"}>
        {!sizes.isMobile && (
          <StickySidebar
            base={base}
            categoryBase={categoryBase}
            allCategoriesText={"Tous les articles"}
            classname={"page-blog__aside"}
            categories={Categories}
            hideDescription={true}
          />
        )}
        <section className={"page-blog__main"}>
          <h1 className={"page-blog__title"}>{title}</h1>

          {!!cat?.description && 
            <div className="page-blog__description" dangerouslySetInnerHTML={{ __html: cat.description }} />
          }

          {sizes.isMobile && (
            <MobileFilters
              base={base}
              categoryBase={categoryBase}
              categories={Categories}
              allCategoriesText={"Tous les articles"}
            />
          )}

          {Articles.length > 0 ? (
            Articles.map(
              ({ title, date, uri, slug, categories, featuredImage }, i) => (
                <ArticleItem
                  key={i}
                  title={title}
                  date={date}
                  categories={categories}
                  // uri={uri}
                  // uri={`/${categories?.nodes?.[0]?.slug}/${slug}`}
                  slug={slug}
                  featuredImage={featuredImage}
                  index={i}
                />
              )
            )
          ) : (
            <p style={{ textAlign: "center", width: "100%" }}>Aucun résultat</p>
          )}
          <Pagination
            prefix={pageContext.prefix}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        </section>
      </div>
    </div>
  )
}

export default Archive
