import Archive from "~/components/blog/archive.js"
import Layout from "~/components/layout/layout"
import React from "react"
import SEO from "~/components/seo.js"
import { graphql } from "gatsby"

// Blog Category Page
const CategoryPage = ({ location, pageContext, data }) => {
  let seo = pageContext.seo;
  if(seo.metaDesc === '')seo.metaDesc = pageContext.cat.description;

  return (
    <Layout location={location}>
      {/* <SEO title="Funky Veggie - Blog" /> */}
      {/* <SEO
        title={pageContext.seo && pageContext.seo.title ? pageContext.seo.title : 'Blog | Funky Veggie'}
        description={pageContext.seo && pageContext.seo.metaDesc ? pageContext.seo.metaDesc : 'Découvrez nos produits'}
      /> */}
      <SEO meta={seo} />
      <Archive pageContext={pageContext} data={data} location={location} />
    </Layout>
  )
}

export const query = graphql`
  query CategoryPosts(
    $categoryDatabaseId: Int!
    $offset: Int!
    $perPage: Int!
  ) {
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      limit: $perPage
      skip: $offset
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        uri
        slug
        date
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    allWpCategory(
      filter: {count: {gt: 0}}
    ) {
      nodes {
        name
        slug
        uri
      }
    }
  }
`

export default CategoryPage
